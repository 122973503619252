<template>
  <the-wrapper :isAside="isAside" :isSpinner="isSpinner">
    <slot>
      <h2>Ваши номера</h2>
      <br />
      <office-current-numbers :userNumbers="userNumbers.ServiceItems"></office-current-numbers>
      <br />
      <office-actual-numbers></office-actual-numbers>
      <br />
      <br />
      <base-back-button></base-back-button>
    </slot>
    <template #aside>
      <the-battery style="padding-top: 4rem"></the-battery>
    </template>
  </the-wrapper>
</template>

<script>
import TheBattery from '@/components/TheBattery.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import OfficeCurrentNumbers from '@/components/Vpbx/virtual_office/OfficeCurrentNumbers.vue';
import OfficeActualNumbers from '@/components/Vpbx/virtual_office/OfficeActualNumbers.vue';
import BaseBackButton from '@/components/shared/buttons/BaseBackButton.vue';
import { getNumbers } from '@/api/vpbx';
import { mapMutations } from 'vuex';
import { catchFunction } from '@/additional_helpers/helpers';

export default {
  data() {
    return {
      userNumbers: {},
      isAside: true,
      isSpinner: true
    };
  },

  components: {
    OfficeCurrentNumbers,
    OfficeActualNumbers,
    BaseBackButton,
    TheBattery,
    TheWrapper
  },

  created() {
    getNumbers()
      .then((resultNumbers) => {
        this.userNumbers = resultNumbers;
        this.SHOW_LOADING(false);
      })
      .catch((e) => catchFunction(e));
  },

  methods: mapMutations({ SHOW_LOADING: 'SHOW_LOADING' })
};
</script>

<style></style>
