<template>
  <div>
    <base-table v-if="userNumbers && userNumbers.length !== 0" :fields="table" :items="userNumbers">
      <template #headerText>
        Список номеров:
      </template>
      <template #cell(PhoneNumberFormatted)="{item}">
        {{ editNumberLook(item.PhoneNumberFormatted) }}
      </template>
      <template #cell(CallingCardGroupId)="{item}">
        <router-link
          v-if="item.CallingCardGroupId"
          :to="{
            name: 'InterCity',
            query: { id: item.CallingCardGroupId },
          }"
        >
          Посмотреть тарифы
        </router-link>
      </template>
    </base-table>
    <base-table v-else>
      <template #headerText>
        Список номеров:
      </template>
      <template #additional>
        <span>Нет подключенных номеров</span>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import { employMaskPhone } from '@/additional_helpers/helpers';

export default {
  props: {
    userNumbers: {
      type: Array,
      require: true
    }
  },

  data() {
    return {
      table: [
        { key: 'PhoneNumberFormatted', label: 'Номер телефона' },
        { key: 'ServiceStatusDesc', label: 'Статус' },
        { key: 'CallingCardGroupId', label: 'Междугородняя связь' }
      ]
    };
  },

  components: {
    BaseTable
  },

  methods: {
    editNumberLook(number) {
      return employMaskPhone(number);
    }
  }
};
</script>

<style module>
.connect {
  text-decoration: underline;
  cursor: pointer;
}
</style>
