var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userNumbers && _vm.userNumbers.length !== 0)?_c('base-table',{attrs:{"fields":_vm.table,"items":_vm.userNumbers},scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v(" Список номеров: ")]},proxy:true},{key:"cell(PhoneNumberFormatted)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.editNumberLook(item.PhoneNumberFormatted))+" ")]}},{key:"cell(CallingCardGroupId)",fn:function(ref){
var item = ref.item;
return [(item.CallingCardGroupId)?_c('router-link',{attrs:{"to":{
          name: 'InterCity',
          query: { id: item.CallingCardGroupId },
        }}},[_vm._v(" Посмотреть тарифы ")]):_vm._e()]}}],null,false,2402811071)}):_c('base-table',{scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v(" Список номеров: ")]},proxy:true},{key:"additional",fn:function(){return [_c('span',[_vm._v("Нет подключенных номеров")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }