<template>
  <div>
    <span @click="visible = !visible" :class="$style.title">
      Подключить номер
    </span>
    <b-collapse v-model="visible">
      <b-row :class="$style.numbersSearchWrap">
        <b-col sm="5" md="4" align-h="start">
          <b-form-input v-model="filteredNumber" type="number" placeholder="Поиск"> </b-form-input>
        </b-col>
        <b-col :class="$style.text" class="dflex" sm="6" align-h="center">
          <h5>Доступно номеров: {{ numbersList.length }}</h5>
        </b-col>
      </b-row>
      <br />
      <b-form>
        <b-row align-h="center">
          <b-col cols="6" md="3" v-for="item in numbersWithPagination" :key="item.Number">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox
                v-model="selected"
                :value="item.Number"
                @change="addNumber(selected)"
                :aria-describedby="ariaDescribedby"
                name="radio-btn"
                inline
                >{{ item.Number }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <div class="dflex align-h-center">
          <base-pagination
            :currentPage="currentPage"
            @previous-data="showPrevious"
            @next-data="showNext"
          ></base-pagination>
        </div>
        <br />
        <b-row>
          <b-col>
            <h5>Вы выбрали:</h5>
            <div class="dflex">
              <h5 v-for="(number, index) in selected" :key="index" :class="$style.selectedNumber">
                {{ number }} {{ '\u00A0' }}
              </h5>
            </div>
          </b-col>
        </b-row>
        <br />
        <div :class="$style.addNumberBtn" class="dflex align-h-center">
          <BaseButton :disabled="selected.length === 0" @click="sendNumbers(selected)">
            <template #icon></template>
            <template #text>Подключить</template>
          </BaseButton>
        </div>
      </b-form>
    </b-collapse>
  </div>
</template>

<script>
import { getNumbers } from '@/api/registration';
import { sendCustomerNewNumbers } from '@/api/vpbx';
import { catchFunction, ShowErrorToUser, ShowSuccesToUser } from '@/additional_helpers/helpers';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      visible: false,
      numbersList: [],
      currentPage: 1,
      numbersPerPage: 36,
      filteredNumber: '',
      selected: [],
      isSelected: false
    };
  },

  components: {
    BasePagination: () => import('@/components/shared/BasePagination.vue'),
    BaseButton: () => import('@/components/shared/buttons/BaseButton.vue')
  },

  mounted() {
    this.addNumber(this.selected);
  },

  created() {
    getNumbers()
      .then((result) => this.numbersList.push(...result))
      .catch((e) => catchFunction(e));
  },

  computed: {
    numbersWithPagination() {
      return this.showNumbersPerPage();
    },

    filteredNumbers() {
      return this.sortFilteredNumbers();
    },

    requestMessage() {
      return this.sendNumbers;
    }
  },

  methods: {
    ...mapActions({
      addNumber: 'vpbx/addNumbers'
    }),

    sortFilteredNumbers() {
      const filtered = this.numbersList.filter((el) => el.Number.includes(this.filteredNumber));
      return filtered.sort((curr, next) => +curr.Number - +next.Number);
    },

    sendNumbers(numbers) {
      sendCustomerNewNumbers(JSON.stringify(numbers))
        .then((result) => {
          if (!result.ok) {
            ShowErrorToUser(JSON.parse(result.text));
            return;
          }
          if (result.ok) {
            ShowSuccesToUser(
              'Спасибо! Ваша заявка принята. Наши специалисты свяжутся с Вами в ближайшее время'
            );
            this.numbersList = this.numbersList.filter((el) => numbers.indexOf(el.Number) < 0);
          }
        })
        .catch((e) => catchFunction(e));
    },

    showNumbersPerPage() {
      const START = (this.currentPage - 1) * this.numbersPerPage;
      const END = this.currentPage * this.numbersPerPage;
      return this.filteredNumbers.slice(START, END);
    },

    showPrevious() {
      this.currentPage -= 1;
    },

    showNext() {
      this.currentPage += 1;
    }
  }
};
</script>

<style lang="scss" module>
.numbersSearchWrap {
  padding-top: 1rem;
}

.text {
  font-size: 1rem;
  text-align: left;
  align-items: center;
}

.title {
  text-decoration: underline;
  font-size: 1.3125rem;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}

.addNumberBtn ~ div {
  text-align: center;
}

.selectedNumber {
  color: $buttonBorderBlue;
}

@media (max-width: 575px) {
  .numbersSearchWrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    & div:first-child {
      padding-bottom: 1rem;
    }
  }

  .text {
    text-align: center;
  }
}

@media (min-width: 576px) {
  .addNumberBtn {
    justify-content: flex-start;
  }

  .addNumberBtn ~ div {
    text-align: left;
  }
}
</style>
